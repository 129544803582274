

.create-content {
    margin:  0  0  20px 0 ;
}

.create-uploads {
    margin:  0  0  20px 0 ;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba($color: #5a7df1, $alpha: 0.2);
}

.select-model {
    margin:  0  0  20px 0 ;
    background-color: rgb(0, 0, 0);

}

.indexing-loader{
    display:hide
}


.file-list {
    display: flex;
    background-color:rgba($color: #625BC4, $alpha: 0.1);
    padding: 10px;
    border-radius: 10px;
    align-items:center;
   
}

.file-list .folder-delete{
  margin-left: auto; /* Son öğeyi en sağa taşımak için */
}
.folders-checkbox{
  height: 24px;
  width: 24px;
  
}
.folders-checboxses{
  display: flex;
   align-items: center; 
   justify-content: center;
   
}

//pagination region
.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Sayfa numaralarını ortalamak için */
    flex-direction: row;
    list-style: none;
    padding: 0;
    position: relative;
    
  }
  
  .pagination-previous
  {
    margin:0 30px;
    padding: 0;
  }
  .pagination-next {
    
    margin:0 30px;
    padding: 0;
  }
  
  .pagination-page {
    display: inline-block;
    margin: 0 10px; /* Sayfa numaraları arasına boşluk eklemek için */
    
  }
  .pagination-active {

    font-weight: bold; /* İsteğe bağlı olarak aktif sayfanın görünümünü ayarlayabilirsiniz */
    color: rgb(168, 184, 255);
  }

  /* Koyu tema seçim kutusu stilleri */
.modelOptions {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color:rgba($color: #000000, $alpha: 0.9);
  color: #fff; /* Metin rengi */
  padding: 0.5rem; /* İç boşluk */
  border: 1px solid #666; /* Kenarlık */
  border-radius: 5px; /* Köşe yuvarlama */
  outline: none; /* Odaklandığında kenarlık olmasın */
  cursor: pointer; /* İmleç */
}

/* Koyu tema seçim kutusu üzerine gelindiğinde stiller */
.modelOptions:hover {
  background-color:rgba($color: #000000, $alpha: 1);
}

/* Koyu tema seçim kutusu seçildiğinde stiller */
.modelOptions:focus {
  border-color: #fff; /* Kenarlık rengi */
  box-shadow: 0 0 0 2px #333; /* Odaklanma gölgesi */
}

.modelOptions option {
  border-radius: 1px;
  border-bottom: 1px solid #666; // Alt kenarlık
  padding: 0.5rem; // İç boşluk
}


.session-settings{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
}
.session-settings span{
  
  border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.6);
  border-radius: 5px;
}


//llms listesi
.llms-inputs {
  background-color: transparent !important; /* Arka plan rengini şeffaf yap */
  border: 2px solid rgba(98, 91, 196, 0.8) !important; /* Kenarlık rengini ve kalınlığını ayarla */

  padding: 5px; /* İçeriği kenarlıktan ayır */
}

.tooltipss{
  display:"block";
}


.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #7ca1c5;
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  /* Pozisyonu ayarla */
  position: absolute;
  z-index: 5;

  margin-left: -60px; /* Tooltip genişliğinin yarısı kadar sola kaydır */
  /* Animasyon */
  opacity: 0;
  transition: opacity 0.5s;
}

/* Tooltip text görünümünü değiştir */
.tooltipss:hover {
  .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}